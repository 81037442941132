<template>
  <div>
    <file-pond
      ref="pond"
      name="mediaFile"
      :allow-multiple="true"
      :allow-reorder="true"
      :files="myFiles"
      height="30"
      :accepted-file-types="filesToAccept"
      class="mb-2 px-1"
    />
    <slot />
    <b-button
      variant="primary"
      class="ml-1 mb-1"
      :disabled="isSaving"
      @click="finishUploadFiles"
    >
      <span
        v-if="isSaving"
        class="d-flex"
      ><b-spinner
         class="mr-1"
         small
         variant="white"
         label="Loading..."
       />
        {{ $t("form.actions.saving") }}
      </span>
      <span v-else>{{ $t("form.actions.save") }}</span>
    </b-button>
  </div>
</template>
<script>
// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
);

export default {
  name: 'MultiFileComponent',
  components: {
    FilePond,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { myFiles: [] };
  },
  computed: {
    filesToAccept() {
      if (this.type === 'image') {
        return 'image/*';
      }
      if (this.type === 'video') {
        return 'video/*';
      }
      if (this.type === 'document') {
        return (
          'application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/rtf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      }
      if (this.type === 'spreadsheet') {
        return (
          'application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      }
      if (this.type === 'audio') {
        return 'audio/mpeg, audio/ogg, audio/wav';
      }
      return null;
    },
  },
  methods: {
    finishUploadFiles() {
      this.isSaving = true;
      const files = this.$refs.pond.getFiles();
      this.$emit('onSubmit', files);
      this.isSaving = false;

      // FilePond instance methods are available on `this.$refs.pond`
    },
  },
};
</script>
<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "~@core/scss/base/components/_variables.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.filepond--item {
    width: calc(50% - 0.5em)!important;
}
.filepond--credits{
    display:none !important;
}
</style>
