<template>
  <b-modal
    :id="`modal-create-media-${type}`"
    :visible="visible"
    size="lg"
    footer-class="d-none"
    @change="$emit('change', $event)"
    @show="resetForm"
  >
    <template #modal-header>
      <language-selector-header :title="title" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
    </template>
    <!-- Body -->
    <!--    <b-button @click="multiFile=false">
      1 File
    </b-button> -->
    <div v-if="!editMedia" class="text-primary p-1 pointer" @click="multiFile=!multiFile">
      <p v-if="multiFile" class="mb-0">
        <b-icon icon="file" variant="primary" />
        {{ $t("media.upload-one-file") }}
      </p>
      <p v-else class="mb-0">
        <b-icon icon="files" variant="primary" />
        {{ $t("media.upload-multiple") }}
      </p>
    </div>
    <multi-file-component
      v-if="multiFile"
      :type="type"
      :is-saving="isSaving"
      @onSubmit="onSubmit"
    >
      <b-alert :show="isSaving" class="text-center">
        {{ $t("media.form.uploading-message") }}
        <b-spinner type="grow" small />
      </b-alert>
    </multi-file-component>
    <validation-observer v-else #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="p-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          :name="$t('media.form.name.name')"
          rules="max:255"
        >
          <b-form-group
            :label="$t('media.form.name.label')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name[selectedLanguage]"
              type="text"
              maxlength="250"
              :disabled="isSaving"
              :placeholder="$t('media.form.name.placeholder')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Headline -->
        <!--         <validation-provider
          #default="validationContext"
          :name="$t('media.form.description.name')"
          rules="max:500"
        >
          <b-form-group :label="$t('backoffice.settings.headline')" label-for="headline">
            <b-form-input
              id="headline"
              v-model="form.headline[selectedLanguage]"
              type="text"
              maxlength="250"
              :disabled="isSaving"
              :placeholder="$t('form-create-item.headline-placeholder')"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider> -->
        <template v-if="editMedia && isLatam">
          <label for="date">{{ $t('media.list.creation-date') }}</label>
          <VueCtkDateTimePicker
            id="date"
            v-model="form.creationDate"
            name="date"
            :locale="$store.state.locale.currentLocale"
            format="YYYY-MM-DD"
            formatted="ll"
            :label="$t('backoffice.availability.date-no-slots')"
            color="var(--primary)"
            no-button-now
            only-date
            button-color="var(--primary)"
            class="mb-1"
          />
        </template>
        <!-- Description -->
        <validation-provider
          #default="validationContext"
          :name="$t('media.form.description.name')"
          rules="max:500"
        >
          <b-form-group
            :label="$t('media.form.description.label')"
            label-for="file-description"
          >
            <b-form-textarea
              v-if="form.description"
              id="file-description"
              v-model="form.description[selectedLanguage]"
              class="my-50"
              rows="5"
              no-resize
              :disabled="isSaving"
              maxlength="500"
              :placeholder="$t('organizations.edit.description.placeholder')"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Checkbox for editable and collaborative document -->
        <b-form-checkbox
          v-if="canUploadCollaboraiveFile && notCollaborative"
          v-model="form.isCollaborative"
          name="check-button"
          switch
        >
          {{ $t("media.form.is-collaborative-document") }}
        </b-form-checkbox>
        <p v-if="!notCollaborative">
          {{ $t("media.form.no-pdf") }}
        </p>
        <b-form-checkbox
          v-if="linkEnabled"
          v-model="form.isLink"
          name="check-button"
          switch
          @change="toggleIsLink"
        >
          {{ $t("media.form.link.is-link") }}
        </b-form-checkbox>
        <validation-provider
          v-if="form.isLink"
          #default="validationContext"
          :name="$t('media.form.link.name')"
          rules="required"
        >
          <b-form-group
            :label="$t('media.form.link.label')"
            label-for="file-link"
          >
            <b-form-input
              id="file-link"
              v-model="form.linkURL"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              :placeholder="$t('media.form.link.placeholder')"
              :disabled="isSaving"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <div v-else>
          <validation-provider
            #default="validationContext"
            ref="fileValidator"
            :name="$t('media.form.file.name')"
          >
            <b-form-group
              :label="$t('media.form.file.label')"
              label-for="file-file"
            >
              <file-preview
                v-if="form.fileURL"
                :src="form.fileURL"
                :state="getValidationState(validationContext)"
                :mime-type="`${type}/`"
                cancelable
                :disabled="isSaving"
                @cancel="
                  form.fileURL = null;
                  validationContext.validate(null);
                "
              />
              <file-upload
                v-else
                v-model="file.file"
                :type="type"
                class="w-100"
                cancelable
                :disabled="isSaving"
                :placeholder="$t('media.form.file.placeholder')"
                :drop-placeholder="$t('media.form.file.drop-placeholder')"
              />
              <b-form-invalid-feedback
                :class="{ 'd-block': validationContext.errors[0] }"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
        <!-- Imatge -->
        <b-form-group
          v-if="type!=='image'"
          :label="$t('media.form.file.thumbnail')"
          label-for="banner"
          class="d-inline-block w-100"
        >
          <file-preview
            v-if="form.thumbnailURL"
            :src="form.thumbnailURL"
            mime-type="image"
            cancelable
            :disabled="isSaving"
            @cancel="form.thumbnailURL = null"
          />
          <file-upload
            v-else
            v-model="image"
            type="image"
            cancelable
            :placeholder="$t('form-create-item.image')"
            :drop-placeholder="$t('form-create-item.drop-placeholder')"
            @cancel="imageSrc = null"
          />
        </b-form-group>
        <b-form-checkbox
          v-if="!form.isLink"
          v-model="form.canDownload"
          :disabled="form.isLink"
          name="check-button"
          switch
        >
          {{ $t("media.form.can-download") }}
        </b-form-checkbox>
        <b-form-checkbox
          v-model="form.isCompletable"
          name="check-button"
          switch
        >
          {{ $t("media.form.is-completable") }}
        </b-form-checkbox>

        <b-alert :show="isSaving" class="text-center">
          {{ $t("media.form.uploading-message") }}
          <b-spinner type="grow" small />
        </b-alert>
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            type="submit"
            :disabled="isSaving"
          >
            <span
              v-if="isSaving"
              class="d-flex"
            ><b-spinner
               class="mr-1"
               small
               variant="white"
               label="Loading..."
             />
              {{ $t("form.actions.saving") }}
            </span>
            <span v-else>{{ $t("form.actions.save") }}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import PlainEditor from '@core/components/editor/PlainEditor.vue';
import formValidation from '@core/comp-functions/forms/form-validation';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import MultiFileComponent from '@/views/apps/media/components/modal/MultiFileComponent.vue';
import { required, max } from '@validations';

export default {
  name: 'FileUploadModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    PlainEditor,
    FileUpload,
    FilePreview,
    FormTranslationTable,
    LanguageSelectorHeader,
    MultiFileComponent,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    // EDIT IS NOT YET IMPLEMENTED
    value: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        return [
          'image',
          'video',
          'document',
          'audio',
          'file',
          'spreadsheet',
          'presentation',
          'csv',
        ].includes(type);
      },
    },
    linkEnabled: Boolean,
    editMedia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: {}, // translation table
        headline: {},
        description: {},
        creationDate: {},
        fileURL: null,
        isLink: false,
        canDownload: false,
        isCompletable: false,
        linkURL: null,
        thumbnailURL: null,
        includeThumbnail: 0,
        isCollaborative: false,
        multiFile: false,
      },

      multiFile: false,
      file: {},
      notCollaborative: true,
      image: null,
      required,
      max,

      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    isLatam() {
      return this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    },
    canUploadCollaboraiveFile() {
      return (
        this.type === 'document'
        || this.type === 'presentation'
        || this.type === 'spreadsheet'
        || this.type === 'file'
      );
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  watch: {
    user() {
      this.resetForm();
    },
    file(oldVal, newVal) {
      if (this.file?.file?.type === 'application/pdf') {
        this.notCollaborative = false;
        this.form.isCollaborative = false;
      }
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});

    return {
      getValidationState,
    };
  },
  created() {
    this.selectedLanguage = this.currentLocale;
    this.resetForm();
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(`modal-create-media-${this.type}`);
    },
    toggleIsLink(isLink) {
      if (isLink) {
        this.file = {};
      } else {
        this.form.linkURL = null;
      }
    },
    resetForm() {
      if (typeof this.value?.name === 'string') {
        const name = this.value?.name;
        this.value.name = {};
        this.value.name[this.selectedLanguage] = name;
      }
      this.form = {
        name: this.value?.name || {},
        headline: this.value?.headline || {},
        description: this.value?.description || {},
        ...(this.editMedia ? { creationDate: new Date(this.value?.createdAt) } : ''),
        fileURL: this.value && !this.value.isLink ? this.value.url : null,
        canDownload: this.value?.canDownload || false,
        isCollaborative: this.value?.isCollaborative || false,
        isCompletable: this.value?.isCompletable || false,
        isLink: this.value?.isLink || false,
        linkURL: this.value?.isLink ? this.value.url : null,
        thumbnailURL: this.value?.thumbnailURL || null,
        includeThumbnail: 0,
      };
      this.notCollaborative = true;
      this.file = {};

      if (this.$refs.refFormObserver) {
        this.$refs.refFormObserver.reset();
      }
    },
    async onSubmit(files) {
      const file = {};

      if (this.multiFile) {
        this.form.multiFile = true;
        for (const [index, oneFile] of files.entries()) {
          file[`mediaFile-${index + 1}`] = oneFile.file;
        }
      } else {
        if (this.editMedia) {
          this.file.thumbnail = null;
          this.image ? this.file.thumbnail = this.image : this.form.thumbnailURL ? '' : this.form.deleteThumbnailURL = true;
        } else {
          this.image ? this.file.thumbnail = this.image : this.file.thumbnail = null;
        }
        file.thumbnail = this.file.thumbnail;
        if (!this.form.isLink) {
          file.mediaFile = this.file.file;
        }
      }
      if (!this.form.isLink && this.file.lenght === 0 && !this.form.fileURL) {
        this.$refs.fileValidator.setErrors(['file field is mandatory']);
        return;
      }
      if (this.file.thumbnail) {
        this.form.includeThumbnail = 1;
      }

      try {
        this.$emit('save', 
          this.form,
          file,
          this.form.isCollaborative
        );
        setTimeout(() => {
        }, 5000);
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}
</style>
